<template>
  <b-modal
    id="coaches-calendar-modal"
    hide-footer
    :title="`Chọn thông tin`"
    size="l"
    :hide-header-close="true"
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-container>
      <b-row>
        <b-col cols="12">
          <basic-select
            label="Chọn khóa"
            placeholder="Chọn khóa"
            name="Course"
            :options="courses"
            :solid="false"
            :required="true"
            :allowEmpty="false"
            track-by="value"
            value-label="text"
            :value.sync="courseSelected"
            v-model="courseSelected"
            changeValueByObject
            data-vv-as="Course"
          />
        </b-col>
        <b-col cols="12">
          <basic-select
            v-if="isAdminBool"
            label="Chọn Coach"
            placeholder="Chọn Coach"
            name="Coach"
            :options="coaches"
            :solid="false"
            :required="true"
            :allowEmpty="false"
            track-by="value"
            value-label="text"
            v-model="coachSelected"
            :value.sync="coachSelected"
            changeValueByObject
            data-vv-as="Coaches"
          />
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end align-items-center mt-4">
        <b-button
          class="btn btn-success ml-3"
          @click="handleSubmit"
          type="submit"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Xác nhận
        </b-button>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions } = createNamespacedHelpers('calendarCoach');
export default {
  name: 'SelectCourseModal',
  data() {
    return {
      coaches: [],
      courses: [],
      courseSelected: {},
      coachSelected: {},
      isAdminBool: true,
      isCoachBool: false,
    };
  },

  props: ['currentUser'],
  methods: {
    ...mapActions(['setCourse', 'setCoachId', 'setCoachName']),

    async handleSubmit() {
      if (Object.keys(this.courseSelected).length === 0) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: 'Không được để trống khóa học',
        });
        return;
      }
      if (this.isAdminBool) {
        if (Object.keys(this.coachSelected).length === 0) {
          this.$toastr.e({
            title: 'Lỗi !',
            msg: 'Vui lòng chọn coach',
          });
          return;
        }
        this.setCoachId(this.coachSelected.value);
        this.setCoachName(this.coachSelected.text);
      } else if (this.isCoachBool) {
        this.setCoachId(this.currentUser?.id);
        this.setCoachName(this.currentUser?.fullName);
      }
      this.setCourse(this.courseSelected);

      await this.$emit('selected-success');
      this.$bvModal.hide('coaches-calendar-modal');
    },

    async getDataCourses() {
      await this.$api
        .get('Courses')
        .then(({ data }) => {
          const { items } = data;
          this.courses = items?.map((c) => {
            return {
              text: c?.name,
              value: c?.id,
              time: c?.startDatetime,
            };
          });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
          return Promise.reject(error);
        });
    },

    async checkRole(roleName) {

      const params = {
        id: this.currentUser?.id,
      };

      const { meta, data, error } = await this.$api.get(
        '/Admin/Account/portal/PrepareCreateUpdateFormItem',
        {
          params: params,
        },
      );

      if (!meta.success) {
        return this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
          preventDuplicates: true,
        });
      }

      return data.roleList.some(
        (role) => role.text === roleName && role.selected == true,
      );
    },

    async isAdmin() {
      this.isAdminBool = await this.checkRole('Admin');
      return this.isAdminBool;
    },

    async isCoach() {
      this.isCoachBool = await this.checkRole('Coach');
      return this.isCoach;
    },

    async choosenCourse() {
      this.$store.commit('context/setLoading', true);

      await this.$api
        .get(`Courses/CoachInCourse?courseId=${this.courseSelected.value}`)
        .then(({ data }) => {
          this.coaches = data?.map((c) => {
            return {
              text: c?.fullName,
              value: c?.id,
            };
          });
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
          return Promise.reject(error);
        });
    },

  },
  watch: {
    courseSelected: {
      handler(newValue) {
        if (newValue) {
          this.coachSelected = null;
          this.choosenCourse();
        }
      },
      deep: true,
    },
  },
  async created() {
    // await Promise.all([this.getDataCourses(), this.isAdmin(), this.isCoach()]);
    // this.$bvModal.show('coaches-calendar-modal');
  },

  beforeMount() {
    Promise.all([this.getDataCourses(), this.isAdmin(), this.isCoach()])
      .then(() => {
        // Xử lý dữ liệu và hiển thị modal
        this.$bvModal.show('coaches-calendar-modal');
      })
      .catch(error => {
        // Xử lý lỗi
        console.error('Đã xảy ra lỗi khi gọi API:', error);
      });
  }

};
</script>
